import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './modules/layout/layout.component';
import { IsSignedInGuard } from './guards/is-signed.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [IsSignedInGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'dataviz',
        loadChildren: () => import('./modules/dataviz/dataviz.module').then((m) => m.DatavizModule)
      },
      {
        path: 'data',
        loadChildren: () => import('./data/data.module').then((m) => m.DataModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/authentication/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/authentication/register/register.module').then((m) => m.RegisterModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./modules/authentication/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./modules/authentication/reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',

      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
