import { of } from 'rxjs/internal/observable/of';
import { filter } from 'rxjs/internal/operators/filter';
import { Subscription } from 'rxjs/internal/Subscription';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { PublicConfService } from './r3Public-conf.service';
import { RunTimeDataService } from 'leandredev_common-ng16/infraService';

import { IHttpResult } from 'leandredev_common_js/dist/httpClient/IHttpResult';
import * as interfaces from '@r3group-digital/common-lib-v2/dist/interfaces';
import { LocalStorageService } from './localStorage.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SignInR3JwtService {
  protected sessionId: string;
  protected sub: string | null = null;
  protected httpSubConf?: Subscription;
  constructor(
    protected runTimeDataService: RunTimeDataService,
    protected http: HttpClient,
    protected publicConfService: PublicConfService,
    protected localStorage: LocalStorageService
  ) {
    this.sessionId = Math.random().toString();
    const expStr: string = this.localStorage.getItem(environment.application_instance + 'jwExp') || '';
    const idTokenStr: string = this.localStorage.getItem(environment.application_instance + 'jwToken') || '';
    if (idTokenStr && expStr && Number(expStr) * 1000 > Date.now()) {
      this.connect({ token: idTokenStr, token_exp: Number(expStr), token_iat: 0 });
    } else {
      this.disconnect();
    }
  }

  protected httpSub?: Subscription;
  protected _isConnecting = false;
  public get isConnecting(): boolean {
    return this._isConnecting;
  }

  protected unAuthorizedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public unAuthorizedObs: Observable<boolean> = this.unAuthorizedSubject.asObservable();

  protected connectedSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  public connectedObs: Observable<boolean> = this.connectedSubject.asObservable().pipe(
    filter((data) => {
      return data !== null;
    })
  );

  public get isConnected(): boolean {
    return Boolean(this.connectedSubject.value);
  }

  protected token?: string;
  public connect(config: { token_exp: number; token_iat: number; token: string }): Observable<boolean> {
    // console.log('SignInQbpJwtService connect start');
    // console.log(`this._isConnecting          : ${this._isConnecting}`);
    // console.log(`config                      : ${config}`);
    // console.log(`config.token                : ${config.token.substring(0, 50)}.....`);
    // console.log(`this.connectedSubject.value : ${this.connectedSubject.value}`);

    if (this._isConnecting) {
      throw new Error('connexion is started');
    }
    if (!config) {
      throw new Error('no config');
    }
    if (!config.token) {
      throw new Error('no idToken');
    }
    if (this.connectedSubject.value) {
      this.disconnect();
    }
    this._isConnecting = true;
    const claim: { sub: string; exp: number } = JSON.parse(atob(config.token.split('.')[1])) as { sub: string; exp: number };
    this.sub = claim.sub;
    this.localStorage.setItem(environment.application_instance + 'jwToken', config.token);

    this.localStorage.setItem(environment.application_instance + 'jwExp', String(Number(claim.exp)));
    // console.log('SignInQbpJwtService connect localStorage.setItems ');
    this.token = config.token;

    this.runTimeDataService.patchStore('token', {
      token: config.token,
      sessonId: this.sessionId,
      userId: this.sub
    });

    this.runTimeDataService.patchStore('UserClaim', claim);
    this.runTimeDataService.patchStore('ConfService', config);
    // console.log('SignInQbpJwtService connect runTimeDataService.patchStore 1 2 3');

    // console.log('SignInQbpJwtService connect clain expt ' + claim.exp);
    if (claim.exp) {
      const tokenDuration: number = (claim.exp - 30) * 1000 - Date.now();
      if (tokenDuration > 0) {
        window.setTimeout(() => {
          this.refreshToken();
        }, tokenDuration);
      } else {
        this.refreshToken();
      }
    }
    // console.log('SignInQbpJwtService connect unAuthorizedSubject.value : ' + this.unAuthorizedSubject.value);
    if (this.unAuthorizedSubject.value) {
      this.unAuthorizedSubject.next(false);
    }

    this.connectedSubject.next(true);
    // console.log('SignInQbpJwtService connect connectedSubject.next(true)');
    this._isConnecting = false;
    // console.log('SignInQbpJwtService connect this._isConnecting = false;');
    // console.log('SignInQbpJwtService connect end');
    return of(true);
  }

  public disconnect(): void {
    if (this._isConnecting) {
      throw new Error('connexion is started');
    }
    this.sub = null;
    this.localStorage.removeItem(environment.application_instance + 'jwToken');
    this.localStorage.removeItem(environment.application_instance + 'jwExp');

    this.runTimeDataService.patchStore('token', null);
    this.runTimeDataService.patchStore('UserClaim', null);
    this.runTimeDataService.patchStore('ConfService', null);
    this.connectedSubject.next(false);
  }

  // public loadConf(token: string): void {
  //   this._isConnecting = true;
  //   const headers = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('JWT', token);
  //   const options = { headers };
  //   this.httpSubConf = this.http.get('/api/authentication/loadConf', options).subscribe({
  //     next: (data: unknown) => {
  //       this._isConnecting = false;
  //       this.httpSubConf?.unsubscribe();
  //       if (!data) {
  //         this.disconnect();
  //       }
  //       const dataRes: IHttpResult<interfaces.Iapplication_configuration_r3_admin & { token_exp: number; token_iat: number; token: string }> =
  //         data as IHttpResult<interfaces.Iapplication_configuration_r3_admin & { token_exp: number; token_iat: number; token: string }>;
  //       if (dataRes.code !== 200) {
  //         this.disconnect();
  //       }

  //       if (!dataRes.response || dataRes.response.length === 0) {
  //         this.disconnect();
  //       }

  //       this.connect(dataRes.response[0]);
  //     },
  //     error: (err) => {
  //       this._isConnecting = false;
  //       this.httpSubConf?.unsubscribe();
  //       this.disconnect();
  //       // console.warn(err);
  //     }
  //   });
  // }

  public refreshToken(): void {
    if (!this.token) {
      if (this.httpSub) {
        this.httpSub.unsubscribe();
      }
      this.disconnect();
      return;
    }
    if (!this.connectedSubject.value) {
      return;
    }
    if (this.httpSub) {
      this.httpSub.unsubscribe();
    }
    const headers = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json').set('JWT', '');
    const options = { headers };
    if (this.httpSub) {
      this.httpSub.unsubscribe();
    }
    this.httpSub = this.http.get('/api/authentication/refreshToken', options).subscribe({
      next: (data) => {
        const conf: IHttpResult<{ token: string; token_exp: number }> = data as IHttpResult<{ token: string; token_exp: number }>;
        if (this.httpSub) {
          this.httpSub.unsubscribe();
        }
        if (conf && conf.code === 200 && conf.response && conf.response[0]) {
          this.token = conf.response[0].token;
          this.runTimeDataService.patchStore('token', {
            token: this.token,
            sessonId: this.sessionId,
            userId: this.sub
          });
          const tokenDuration: number = (conf.response[0].token_exp - 30) * 1000 - Date.now();
          if (tokenDuration > 0) {
            window.setTimeout(() => {
              this.refreshToken();
            }, tokenDuration);
          } else {
            this.refreshToken();
          }
        } else {
          this.disconnect();
        }
      },
      error: (err) => {
        if (this.httpSub) {
          this.httpSub.unsubscribe();
        }
        // console.warn(err);
        this.disconnect();
      }
    });
  }
}
