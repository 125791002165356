import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material-components.module';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { BreadCrumbsService } from 'src/@fury/services/breadcrumbs.service';

@NgModule({
  imports: [CommonModule, MaterialModule, RouterModule],
  declarations: [BreadcrumbsComponent],
  exports: [BreadcrumbsComponent],
  providers: [BreadCrumbsService]
})
export class BreadcrumbsModule {}
