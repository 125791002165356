import { Component } from '@angular/core';
import { map } from 'rxjs/operators';

import { ThemeService } from '../../../../@fury/services/theme.service';

@Component({
  selector: 'fury-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  visible$ = this.themeService.config$.pipe(map((config) => config.footerVisible));

  constructor(private themeService: ThemeService) {}

  hide(): void {
    this.themeService.setFooterVisible(false);
  }
}
