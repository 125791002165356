import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';

import { ThemeService } from '../../../@fury/services/theme.service';
import { SidebarDirective } from '../../../@fury/shared/sidebar/sidebar.directive';
import { checkRouterChildsData } from '../../../@fury/utils/check-router-childs-data';
import { SidenavService } from './sidenav/sidenav.service';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {
  @ViewChild('configPanel', { static: true }) configPanel: SidebarDirective;

  sidenavOpen$ = this.sidenavService.open$;
  sidenavMode$ = this.sidenavService.mode$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavExpanded$ = this.sidenavService.expanded$;
  quickPanelOpen: boolean;

  sideNavigation$ = this.themeService.config$.pipe(map((config) => config.navigation === 'side'));
  topNavigation$ = this.themeService.config$.pipe(map((config) => config.navigation === 'top'));
  toolbarVisible$ = this.themeService.config$.pipe(map((config) => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map((config) => config.toolbarPosition));
  footerPosition$ = this.themeService.config$.pipe(map((config) => config.footerPosition));

  scrollDisabled$ = this.router.events.pipe(
    filter<NavigationEnd>((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => void checkRouterChildsData(this.router.routerState.root.snapshot, (data) => data.scrollDisabled))
  );

  constructor(
    private sidenavService: SidenavService,
    private themeService: ThemeService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  openQuickPanel(): void {
    this.quickPanelOpen = true;
  }

  openConfigPanel(): void {
    this.configPanel.open();
  }

  closeSidenav(): void {
    this.sidenavService.close();
  }

  openSidenav(): void {
    this.sidenavService.open();
  }

  ngOnDestroy(): void {
    return;
  }
}
