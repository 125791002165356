import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  protected storage: { [key: string]: string } = {};
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  public removeItem(key: string): void {
    delete this.storage[key];
    if (isPlatformBrowser(this.platformId)) {
      if (window && window.localStorage) {
        window.localStorage.removeItem(key);
      }
    }
  }

  public setItem(key: string, value: string): void {
    this.storage[key] = value;
    if (isPlatformBrowser(this.platformId)) {
      if (window && window.localStorage) {
        window.localStorage.setItem(key, value);
      }
    }
  }

  public clear(): void {
    this.storage = {};
    if (isPlatformBrowser(this.platformId)) {
      if (window && window.localStorage) {
        window.localStorage.clear();
      }
    }
  }

  public getItem(key: string): string | null {
    if (isPlatformBrowser(this.platformId)) {
      if (window && window.localStorage) {
        return window.localStorage.getItem(key);
      }
    }
    return this.storage[key];
  }

  public key(index: number): string | null {
    if (isPlatformBrowser(this.platformId)) {
      if (window && window.localStorage) {
        return window.localStorage.key(index);
      }
    }
    return Object.keys(this.storage)[index];
  }

  public get length(): number {
    if (isPlatformBrowser(this.platformId)) {
      if (window && window.localStorage) {
        return window.localStorage.length;
      }
    }
    return Object.keys(this.storage).length;
  }
}
