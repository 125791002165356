import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ActivatedRoute, ActivatedRouteSnapshot, Data } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, filter } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BreadCrumbsService {
  /**
   *
   */
  public breadCrumbsData: BehaviorSubject<{ title: string; url: string }> = new BehaviorSubject(null);
  public breadCrumbs: Observable<{ title: string; url: string }> = this.breadCrumbsData.asObservable();
  private oldTitle: string;

  private previusUrl: string;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const eventUrl: string = event.url;

        if (eventUrl.includes('list')) {
          this.previusUrl = eventUrl;
          if (this.get()['title']) {
            this.oldTitle = this.get()['title'];
          } else {
            throw new Error('route has not title propertie, please add this to router data');
          }
          this.breadCrumbsData.next(null);
        }
        if (event.url === '/') {
          this.oldTitle = '';
          this.setPageTilte('QBP ADMIN ACCUEIL');
        }
        if (event.url.includes('login')) {
          this.oldTitle = '';
          this.setPageTilte('QBP-ADMIN Page de connexion');
        }

        if (event['url'].includes('edit')) {
          this.breadCrumbsData.next({ title: this.oldTitle, url: this.previusUrl });
        }
      }
    });
  }

  public setPageTilte(title: string) {
    this.title.setTitle(title);
  }
  private getRouteSnapshot(): ActivatedRouteSnapshot {
    let route = this.route;

    while (route.firstChild) {
      route = route.firstChild;
    }

    return route.snapshot;
  }
  get(): Data {
    return this.getRouteSnapshot().data;
  }

  public navigate(url: string) {
    this._router.navigate([url]);
  }
}
