import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'UserProfile',
    loadChildren: () => import('../generated/routes/UserProfile.module').then((m) => m.UserProfileModule)
  },
  {
    path: 'AdminUserProfile',
    loadChildren: () => import('../generated/routes/AdminUserProfile.module').then((m) => m.AdminUserProfileModule)
  },
  {
    path: 'Partner',
    loadChildren: () => import('../generated/routes/Partner.module').then((m) => m.PartnerModule)
  },
  {
    path: 'CustomerUserProfile',
    loadChildren: () => import('../generated/routes/CustomerUserProfile.module').then((m) => m.CustomerUserProfileModule)
  },
  {
    path: 'Company',
    loadChildren: () => import('../generated/routes/Company.module').then((m) => m.CompanyModule)
  },
  {
    path: 'CompanyEntity',
    loadChildren: () => import('../generated/routes/CompanyEntity.module').then((m) => m.CompanyEntityModule)
  },
  {
    path: 'SummarySurvey',
    loadChildren: () => import('../generated/routes/SummarySurvey.module').then((m) => m.SummarySurveyModule)
  },
  {
    path: 'SurveyTheme',
    loadChildren: () => import('../generated/routes/SurveyTheme.module').then((m) => m.SurveyThemeModule)
  },
  {
    path: 'SectorSurvey',
    loadChildren: () => import('../generated/routes/SectorSurvey.module').then((m) => m.SectorSurveyModule)
  },
  {
    path: 'Action',
    loadChildren: () => import('../generated/routes/Action.module').then((m) => m.ActionModule)
  },
  {
    path: 'ShareClass',
    loadChildren: () => import('../generated/routes/ShareClass.module').then((m) => m.ShareClassModule)
  },
  {
    path: 'SurveyTheme',
    loadChildren: () => import('../generated/routes/SurveyTheme.module').then((m) => m.SurveyThemeModule)
  },
  {
    path: 'CompanySector',
    loadChildren: () => import('../generated/routes/CompanySector.module').then((m) => m.CompanySectorModule)
  },
  {
    path: 'FormSectorSurvey',
    loadChildren: () => import('../generated/routes/FormSectorSurvey.module').then((m) => m.FormSectorSurveyModule)
  },
  {
    path: 'FormSectorSurvey_String',
    loadChildren: () => import('../generated/routes/FormSectorSurvey_String.module').then((m) => m.FormSectorSurvey_StringModule)
  },
  {
    path: 'FormSectorSurvey_Number',
    loadChildren: () => import('../generated/routes/FormSectorSurvey_Number.module').then((m) => m.FormSectorSurvey_NumberModule)
  },
  {
    path: 'Content',
    loadChildren: () => import('../generated/routes/Content.module').then((m) => m.ContentModule)
  },
  {
    path: 'LandingPage',
    loadChildren: () => import('../generated/routes/LandingPage.module').then((m) => m.LandingPageModule)
  },
  {
    path: 'ActionCard',
    loadChildren: () => import('../generated/routes/ActionCard.module').then((m) => m.ActionCardModule)
  },
  {
    path: 'SummaryIntroductionPage',
    loadChildren: () => import('../generated/routes/SummaryIntroductionPage.module').then((m) => m.SummaryIntroductionPageModule)
  },
  {
    path: 'SummarySynthesisPage',
    loadChildren: () => import('../generated/routes/SummarySynthesisPage.module').then((m) => m.SummarySynthesisPageModule)
  },
  {
    path: 'RoadmapPage',
    loadChildren: () => import('../generated/routes/RoadmapPage.module').then((m) => m.RoadmapPageModule)
  },
  {
    path: 'HtmlPage',
    loadChildren: () => import('../generated/routes/HtmlPage.module').then((m) => m.HtmlPageModule)
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: []
})
export class DataModule {}
