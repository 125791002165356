import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
//import { interfaces } from '@quelbonplan/qb-common-lib-v2';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/dataService/dataService.service';

import { ThemeService } from '../../../../@fury/services/theme.service';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Input()
  @HostBinding('class.no-box-shadow')
  hasNavigation: boolean;

  @Output() openSidenav = new EventEmitter();
  @Output() openQuickPanel = new EventEmitter();
  public profile: any; //interfaces.IUserAdmin;
  topNavigation$ = this.themeService.config$.pipe(map((config) => config.navigation === 'top'));

  constructor(
    private themeService: ThemeService,
    protected dataService: DataService
  ) {}

  ngOnInit() {
    /* this.dataService.profile.subscribe((profile) => {
      this.profile = profile;
    });*/
  }
}
