import { Dataviz } from '@r3group-digital/ng16-lib-v2/httpClient/services/dataviz';

import { ApiHelper } from 'leandredev_common-ng16/httpClient';
import { Api_plateforme } from '@r3group-digital/ng16-lib-v2/httpClient/services/api';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as interfaces from '@r3group-digital/common-lib-v2/dist/interfaces';

export class DataVizService {
  constructor(
    private api: Api_plateforme,
    public service: Dataviz
  ) {}
  public companySector(): Observable<interfaces.ICompanySector[]> {
    return this.api.collections.CompanySector.get('*').pipe(ApiHelper.resultToArrWEmptyError('erro  dan sla rechece de scompany secteur'));
  }

  public findPartners(): Observable<interfaces.IPartner[]> {
    return this.api.collections.Partner.get('*').pipe(ApiHelper.resultToArrWEmptyError('erro  dans la recuperation de partners '));
  }

  public companySize(): Observable<interfaces.IFormSectorSurveyAnswer_Number[]> {
    return this.api.collections.FormSectorSurvey_Number.get('/_id/615c07d07f5556e893fe19ad/=/').pipe(
      ApiHelper.resultToObj('error dans la rechetche des company secteur'),
      map((data) => {
        if (data.answers) {
          return data.answers;
        } else {
          return [];
        }
      })
    );
  }

  public summarysurveyTree(sectorId: string): Observable<interfaces.ISummarySurvey> {
    return this.api.collections.CompanySector.get(`_id/${sectorId}/=/`).pipe(
      ApiHelper.resultToObj('error dans la rechetche des company secteur 2'),
      switchMap((res1) => {
        return this.service.summarysurveyTree({}, `${res1.summaryOfThemes}`, {});
      }),
      ApiHelper.resultToObj('error dans la rechetche des company secteur 2'),
      map((data) => {
        return data;
      })
    );
  }

  public getData(dataTypeId: string, filter: interfaces.IDataVizObjectFilter[]): Observable<interfaces.IDatavizResponse> {
    const datavisRequest: interfaces.IDatavizRequest = {
      viewName: dataTypeId,
      filter: filter
    };

    return this.service.compute(datavisRequest, {}, {}).pipe(
      ApiHelper.resultToObj(`error dans la recherche des données calculées: ${JSON.stringify(datavisRequest)}`),
      map((data) => {
        return data;
      })
    );
  }
}
