/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';

import { SidenavItem } from './sidenav-item/sidenav-item.interface';

@Injectable()
export class SidenavNavigationData {
  openQuickPanel(): void {
    return;
  }

  navigationAppItems: SidenavItem[] = [
    { name: 'Accueil', routeOrFunction: '/dataviz', position: 1 },
    {
      name: 'Utilisateurs',
      position: 2,
      icon: 'pages',
      type: 'item',

      subItems: [
        { name: 'Administrateurs', routeOrFunction: 'data/AdminUserProfile/list', position: 1 },
        {
          routeOrFunction: 'data/CustomerUserProfile/list',
          name: 'Utilisateurs',
          position: 2
        }
      ]
    },
    {
      name: 'Partenaires',
      position: 3,
      icon: 'pages',
      type: 'item',

      subItems: [
        {
          routeOrFunction: 'data/Partner/list',
          name: 'Partenaires',
          position: 1
        },
        { routeOrFunction: '/data/LandingPage/list', name: 'Landing Page', position: 2 },
        { routeOrFunction: '/data/HtmlPage/list', name: 'Pages Légales', position: 3 }
      ]
    },
    {
      name: 'Enquêtes sectorielles',
      position: 4,
      icon: 'pages',
      type: 'item',

      subItems: [
        { routeOrFunction: '/data/SectorSurvey/list', name: 'liste des enquêtes sectorielles', position: 1 },
        { routeOrFunction: '/data/FormSectorSurvey/list', name: 'Questioins pour les enquêtes', position: 2 },
        { routeOrFunction: '/data/CompanySector/list', name: "Secteur d'activité d'une entreprise", position: 3 },
        { routeOrFunction: '/data/ShareClass/list', name: "Catégories d'action", position: 4 }
      ]
    },
    {
      name: 'Questionaires',
      position: 5,
      icon: 'pages',
      type: 'item',

      subItems: [
        {
          routeOrFunction: 'data/SummarySurvey/list',
          name: 'Liste des questionaires',
          position: 1
        },
        { routeOrFunction: '/data/Action/list', name: 'Actions/questions', position: 2 },
        { routeOrFunction: '/data/SurveyTheme/list', name: 'Liste des thèmes de questions', position: 3 },
        { routeOrFunction: '/data/SummaryIntroductionPage/list', name: 'liste des pages introductions', position: 4 },
        { routeOrFunction: '/data/SummarySynthesisPage/list', name: 'liste des pages de Synthèses', position: 5 },
        { routeOrFunction: '/data/RoadmapPage/list', name: 'liste des pages de Sortie', position: 6 },
        { routeOrFunction: '/data/Content/list', name: 'Articles', position: 7 },
        { routeOrFunction: '/data/ActionCard/list', name: 'Fiche Action', position: 8 }
      ]
    },
    {
      name: 'Companies',
      icon: 'perm_media',
      position: 6,

      customClass: 'first-subheading',
      subItems: [
        {
          routeOrFunction: 'data/Company/list',
          name: 'Companies',
          position: 1
        },
        {
          routeOrFunction: 'data/CompanyEntity/list',
          name: 'Entités',
          position: 2
        }
      ]
    }
  ];
}
