import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControlBase } from 'leandredev_common-ng16/form';
import { SignInJwtService } from 'leandredev_common-ng16/infraService';
import { Platform } from '@angular/cdk/platform';
import { form } from '@r3group-digital/ng16-lib-v2';
import { filter } from 'rxjs';
import { AppVersion } from './AppVersion';
import { DataService } from './dataService/dataService.service';
import { SplashScreenService } from 'src/@fury/services/splash-screen.service';
import { ThemeService } from 'src/@fury/services/theme.service';
import { SidenavService } from './modules/layout/sidenav/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  hasBeenConnected = false;
  title = 'qbp-admin';
  constructor(
    protected dataService: DataService,
    signInJwtService: SignInJwtService,
    private sidenavService: SidenavService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private route: ActivatedRoute,
    private router: Router,
    private splashScreenService: SplashScreenService
  ) {
    FormControlBase.classes = form;

    console.info(`%c Version: ${AppVersion.appVersion}`, 'background:#ff0000;color:#FFFFFF;font-size:50px');

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('style')))
      .subscribe((queryParamMap) => this.themeService.setStyle(queryParamMap.get('style')));

    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');

    this.themeService.theme$.subscribe((theme) => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.sidenavService.initNavigationWithData();

    signInJwtService.connectedObs.subscribe({
      next: (result) => {
        if (result && !this.hasBeenConnected) {
          this.hasBeenConnected = true;
        } else if (!result && this.hasBeenConnected) {
          this.hasBeenConnected = false;
          void this.router.navigate(['login']);
        }
      },
      error: (err) => {
        console.warn(err);
      }
    });
  }
}
