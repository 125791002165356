/* eslint-disable */
import { Injectable } from '@angular/core';
import { interfaces } from '@r3group-digital/common-lib-v2';
import { read, utils, WorkBook, WorkSheet, writeFileXLSX as writeFile } from 'xlsx';

export interface DataForAddSheet {
  sheet: WorkSheet;
  name: string;
}
type AOA = any[][];
@Injectable({
  providedIn: 'root'
})
export class CsvConverterService {
  constructor() {}
  extractCsvDataForAction(file: File): Promise<interfaces.IAction[]> {
    return this.readFileContent(file).then((wb: WorkBook) => {
      const firstSheetName = wb.SheetNames[0];
      const data: any[] = utils.sheet_to_json(wb.Sheets[firstSheetName]);
      return Promise.all(
        data.map(async (obj: any) => {
          return this.convertSheetLineToAction(obj);
        })
      );
    });
  }

  extractCsvDataForActionCard(file: File): Promise<interfaces.IActionCard[]> {
    return this.readFileContent(file).then((wb: WorkBook) => {
      const arrayOfCardaction = wb.SheetNames.map((sheetName: string) => {
        const ws: WorkSheet = wb.Sheets[sheetName];
        return this.convertSheetToActionCard(ws);
      });

      return arrayOfCardaction;
    });
  }

  createXLSXSheetFromCsv(data: any): DataForAddSheet {
    const arrayOfArrayCsv = data
      .trim()
      .split(/\r\n|\n/)
      .map((row: string) => {
        return row.split(';');
      });
    const sheetData = <AOA>utils.aoa_to_sheet(arrayOfArrayCsv);
    //return { sheet: sheetData, name: (Date.now()*Math.random()).toString() };
    return { sheet: sheetData, name: arrayOfArrayCsv[0][0] };
  }

  writeXlSXFileFromJsonData(sheets: DataForAddSheet[], filename: string): void {
    const exportData = this.formatFilaNameForExport();

    const execelBook: WorkBook = utils.book_new();
    sheets.forEach((sheet) => {
      utils.book_append_sheet(execelBook, sheet.sheet, sheet.name);
    });
    writeFile(execelBook, `${filename}_${exportData}.xlsx`);
  }

  downLoadFileXLSX(data: any[], fileName: string): void {
    const sheets = data.map((base64string) => {
      const byteString = this.b64DecodeUnicode(base64string);
      console.log(byteString);
      return this.createXLSXSheetFromCsv(byteString);
    });

    this.writeXlSXFileFromJsonData(sheets, fileName);
  }

  private readFileContent(file: File): Promise<WorkBook> {
    return new Promise<WorkBook>((resolve, reject) => {
      if (!file) {
        reject(Error('donnes erronées'));
      }

      const reader = new FileReader();

      reader.onload = (e: any) => {
        const ab: ArrayBuffer = e.target.result;
        const wb: WorkBook = read(ab, { type: 'binary' });

        resolve(wb);
      };
      reader.onprogress = (event: any) => {};
      reader.onloadend = (e: any) => {
        //  const text = e.currentTarget.result.toString();
        // resolve(text);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private convertSheetToActionCard(sheet: WorkSheet): interfaces.IActionCard {
    const data: any[] = utils.sheet_to_json(sheet);

    const actionCard: interfaces.IActionCard = {
      _id: data[0]._id ? data[0]._id : undefined,
      name: data[0].name,
      action: data[0].action,
      content: []
    };

    const content: interfaces.IActionCardContent[] = data.map((item) => {
      const actionCardCOnten: interfaces.IActionCardContent = {
        name: item.contentName,
        paragraph: item.contentParagraph
      };
      return actionCardCOnten;
    });
    actionCard.content = content;
    return actionCard;
  }

  private async convertSheetLineToAction(dataForCeatreAction: any): Promise<interfaces.IAction> {
    const action: interfaces.IAction = {
      _id: dataForCeatreAction._id,
      name: dataForCeatreAction.name,
      question: dataForCeatreAction.question,
      actionLabel: dataForCeatreAction.actionLabel,

      didYouKnow: dataForCeatreAction.didYouKnow,
      issue: dataForCeatreAction.issue,
      issue_pop: dataForCeatreAction.issue_pop
    };

    if (dataForCeatreAction.impact) {
      action.impact = await this.stringToNumber(`${dataForCeatreAction.impact}`);
    }
    if (dataForCeatreAction.feasibility) {
      action.feasibility = await this.stringToNumber(`${dataForCeatreAction.feasibility}`);
    }

    if (dataForCeatreAction.didYouKnow_pop_name !== '') {
      action.didYouKnow_pop = {
        name: dataForCeatreAction.didYouKnow_pop_name,
        title: dataForCeatreAction.didYouKnow_pop_title,
        introduction: dataForCeatreAction.didYouKnow_pop_introduction,
        body: dataForCeatreAction.didYouKnow_pop_body
      };
    }

    return Promise.resolve(action);
  }
  private stringToNumber(str: string): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        resolve(Number(str.replace(/[^0-9]/gm, '')));
      } catch (err) {
        reject(err);
      }
    });
  }

  private b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }
  private formatFilaNameForExport(): string {
    const exportData: string = new Date().toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
      second: '2-digit'
    });

    // eslint-disable-next-line no-useless-escape
    return exportData.replace(/[ \/:,]/gm, '_');
  }
}
