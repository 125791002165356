import { Injectable } from '@angular/core';
import { Adaptater } from 'leandredev_common-ng16/components';
//import { api_collection_plateform } from '@lenadredev/r3-ng16-lib-v2';
import { Observable, of } from 'rxjs';

import { api_collection_plateform } from '@r3group-digital/ng16-lib-v2/httpClient/services/api';
import { DataService } from './dataService.service';
@Injectable({
  providedIn: 'root'
})
export class DataServiceAdapterService extends Adaptater {
  constructor(protected dataService: DataService) {
    super();
  }

  override fsoUrlResolver(): Observable<string> {
    return of('/api/file/');
  }

  override getProxyData(colName: keyof api_collection_plateform, idRef: string): Observable<string> {
    return this.dataService.getProxyData(colName, idRef);
  }
}
