<div class="sidenav" fxLayout="column">
  <fury-scrollbar class="sidenav-items" fxFlex="auto">
    <div *ngIf="sidenavUserVisible$ | async" class="sidenav-user">
      <img src="/assets/img/r3-impact.png" />

      <h3 class="sidenav-user-name" *ngIf="profile">{{ profile.name }}</h3>
      <h4 class="sidenav-user-email" *ngIf="profile">{{ profile.email }}</h4>
    </div>

    <fury-sidenav-item *ngFor="let item of items$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </fury-scrollbar>
</div>
