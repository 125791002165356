import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { interfaces } from '@r3group-digital/common-lib-v2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/dataService/dataService.service';

import { ThemeService } from 'src/@fury/services/theme.service';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';

import { SidenavNavigationData } from './sidenav.data';
import { SignInR3JwtService } from 'src/app/dataService/signIn-r3-jwt.service';
import { RunTimeDataService } from 'leandredev_common-ng16/infraService';

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  // #region Properties (6)
  @Output() openQuickPanel = new EventEmitter();

  @Input()
  @HostBinding('class.collapsed')
  public collapsed: boolean;
  @Input()
  @HostBinding('class.expanded')
  public expanded: boolean;

  items$: Observable<SidenavItem[]>;
  public profile: interfaces.ICustomerUserProfile;
  sidenavUserVisible$ = this.themeService.config$.pipe(map((config) => config.sidenavUserVisible));

  // #endregion Properties (6)

  // #region Constructors (1)

  constructor(
    private router: Router,
    protected dataService: DataService,
    protected runTimeDataService: RunTimeDataService,
    private sidenavService: SidenavService,
    private themeService: ThemeService,
    protected signInService: SignInR3JwtService,
    private navigationData: SidenavNavigationData
  ) {}

  // #endregion Constructors (1)

  // #region Public Methods (4)

  @HostListener('mouseenter')
  @HostListener('touchenter')
  public onMouseEnter(): void {
    this.sidenavService.setExpanded(true);
  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  public onMouseLeave(): void {
    this.sidenavService.setExpanded(false);
  }

  public ngOnInit() {
    this.items$ = this.sidenavService.items$.pipe(map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position')));

    this.runTimeDataService.getObservableStore('UserClaim').subscribe({
      next: (claim) => {
        this.profile = claim;
      }
    });
    // this.dataService.profile.subscribe((profile) => {
    //   this.profile = profile;
    //   // if (this.profile.isSuperAdmin) {
    //   //   this.sidenavService.addAdminNavigationData();
    //   // }
    // });

    this.navigationData.openQuickPanel = () => {
      this.openQuickPanel.emit();
    };
  }

  public toggleCollapsed(): void {
    this.sidenavService.toggleCollapsed();
  }

  public disconnect(): void {
    this.signInService.disconnect();

    this.router.navigate(['/login']).catch((err) => {
      console.warn(err);
    });
  }
  // #endregion Public Methods (4)
}
