/* eslint-disable @typescript-eslint/quotes */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlScape'
})
export class HtmlScapePipe implements PipeTransform {
  transform(value: string): string {
    const result = value
      .replace(/%27/g, "'")
      .replace(/%20/g, ' ')
      .replace(/%E9/g, 'é')
      .replace(/%26/g, '&')
      .replace(/%E8/g, 'è')
      .replace(/%E0/g, 'à');

    return result;
  }
}
