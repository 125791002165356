import { HttpClient } from '@angular/common/http';
import { HttpServiceBase } from 'leandredev_common-ng16/httpClient';
import { RunTimeDataService } from 'leandredev_common-ng16/infraService';

import { Api_plateforme, api_collection_plateform } from '@r3group-digital/ng16-lib-v2/httpClient/services/api';
import { Observable, of, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Authentication } from '@r3group-digital/ng16-lib-v2/httpClient/services/authentication';
import { SectorSurvey } from '@r3group-digital/ng16-lib-v2/httpClient/services/sectorSurvey';
import { CsvFileService } from '@r3group-digital/ng16-lib-v2/httpClient/services/csvFileService';
import { AdminPartner } from '@r3group-digital/ng16-lib-v2/httpClient/services/AdminPartner';
import { Dataviz } from '@r3group-digital/ng16-lib-v2/httpClient/services/dataviz';
import { CsvConverterService } from './csvConverter.service';
import { DataVizService } from './dataViz.service';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  // TODO : Remove this
  public api: Api_plateforme;
  public authService: Authentication;
  sectorSurveyService: SectorSurvey;
  public csvConverter: CsvConverterService;
  public csvFileService: CsvFileService;
  public dataViz: Dataviz;
  public dataVizService: DataVizService;
  public adminPartner: AdminPartner;
  public proxyObj: { [key: string]: { [key: string]: any } } = {};
  public proxyObservable: {
    [key: string]: { [key: string]: Observable<any> };
  } = {};

  constructor(
    private http: HttpClient,
    private runTimeDataService: RunTimeDataService
  ) {
    this.api = new Api_plateforme(this.http);
    this.authService = new Authentication(this.http, false);
    this.sectorSurveyService = new SectorSurvey(this.http, true);
    this.csvFileService = new CsvFileService(this.http, true);
    this.csvConverter = new CsvConverterService();
    this.adminPartner = new AdminPartner(this.http, true);
    this.dataViz = new Dataviz(this.http, true);
    this.dataVizService = new DataVizService(this.api, this.dataViz);

    this.api.urlApi = '/api/bdd/';
    this.authService.urlCollection = `/api/authentication/`;
    this.sectorSurveyService.urlCollection = `/api/sector-survey/`;
    this.csvFileService.urlCollection = `/api/cvsfileservice/`;
    this.adminPartner.urlCollection = `/api/adminPartner/`;
    this.dataViz.urlCollection = '/api/dataviz/';
  }

  public getProxyData<T>(columnName: keyof api_collection_plateform, idObj: string): Observable<T> {
    const colName: string = columnName as string;

    if (this.proxyObj[colName] && this.proxyObj[colName][idObj]) {
      return of(this.proxyObj[colName][idObj]);
    } else if (this.proxyObservable[colName] && this.proxyObservable[colName][idObj]) {
      return this.proxyObservable[colName][idObj];
    } else {
      if (!this.proxyObj[colName]) {
        this.proxyObj[colName] = {};
      }
      if (!this.proxyObservable[colName]) {
        this.proxyObservable[colName] = {};
      }

      const caller: HttpServiceBase<T> = this.api.collections[columnName] as HttpServiceBase<T>;
      this.proxyObservable[colName][idObj] = caller.get(idObj).pipe(
        map((dataRes) => {
          delete this.proxyObservable[colName][idObj];
          if (dataRes.code === 200) {
            if (dataRes.response.length > 0) {
              this.proxyObj[colName][idObj] = dataRes.response[0];
              return this.proxyObj[colName][idObj];
            } else {
              return null;
            }
          } else {
            return null;
          }
        })
      );

      return this.proxyObservable[colName][idObj];
    }
  }
}
