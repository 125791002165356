import { Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { map } from 'rxjs/operators';

import { Theme, ThemeService } from '../../../../@fury/services/theme.service';
import { SidenavService } from '../sidenav/sidenav.service';

@Component({
  selector: 'fury-config-panel',
  templateUrl: './config-panel.component.html',
  styleUrls: ['./config-panel.component.scss']
})
export class ConfigPanelComponent {
  activeTheme$ = this.themeService.activeTheme$;
  navigation$ = this.themeService.config$.pipe(map((config) => config.navigation));
  sidenavOpen$ = this.sidenavService.open$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavUserVisible$ = this.themeService.config$.pipe(map((config) => config.sidenavUserVisible));
  toolbarVisible$ = this.themeService.config$.pipe(map((config) => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map((config) => config.toolbarPosition));
  footerVisible$ = this.themeService.config$.pipe(map((config) => config.footerVisible));
  footerPosition$ = this.themeService.config$.pipe(map((config) => config.footerPosition));

  constructor(
    private themeService: ThemeService,
    private sidenavService: SidenavService
  ) {}

  setActiveTheme(theme: Theme): void {
    this.themeService.setTheme(theme);
  }

  navigationChange(change: MatRadioChange): void {
    switch (change.value) {
      case 'top':
        this.themeService.setNavigation('top');
        break;
      case 'side':
        this.themeService.setNavigation('side');
        break;
    }
  }

  sidenavOpenChange(change: MatSlideToggleChange): void {
    change.checked ? this.sidenavService.open() : this.sidenavService.close();
  }

  sidenavCollapsedChange(change: MatCheckboxChange): void {
    this.sidenavService.setCollapsed(change.checked);
  }

  sidenavUserChange(change: MatCheckboxChange): void {
    this.themeService.setSidenavUserVisible(change.checked);
  }

  toolbarVisibleChange(change: MatSlideToggleChange): void {
    this.themeService.setToolbarVisible(change.checked);
  }

  toolbarPositionChange(change: MatRadioChange): void {
    switch (change.value) {
      case 'fixed':
        this.themeService.setToolbarPosition('fixed');
        break;
      case 'above-fixed':
        this.themeService.setToolbarPosition('above-fixed');
        break;
      case 'static':
        this.themeService.setToolbarPosition('static');
        break;
    }
  }

  footerVisibleChange(change: MatSlideToggleChange): void {
    this.themeService.setFooterVisible(change.checked);
  }

  footerPositionChange(change: MatRadioChange): void {
    switch (change.value) {
      case 'fixed':
        this.themeService.setFooterPosition('fixed');
        break;
      case 'above-fixed':
        this.themeService.setFooterPosition('above-fixed');
        break;
      case 'static':
        this.themeService.setFooterPosition('static');
        break;
    }
  }
}
