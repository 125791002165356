import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BreadCrumbsService } from 'src/@fury/services/breadcrumbs.service';

@Component({
  selector: 'fury-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  private _current: string = '';
  @Input() set current(value: string) {
    this._current = value;
  }

  get current(): string {
    return this._current;
  }
  @Input() crumbs: { title: string; url: string }[] = [];

  constructor(private breadcrumbservice: BreadCrumbsService) {}

  ngOnInit() {
    this.breadcrumbservice.breadCrumbs.subscribe((crumb) => {
      if (crumb && crumb.title !== this.current) {
        this.crumbs = [crumb];
      } else {
        this.crumbs = [];
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.breadcrumbservice.setPageTilte(this.current);
  }
  public navigate(url: string): void {
    void this.breadcrumbservice.navigate(url);
  }
}
