import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav.component';
import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';
import { SidenavNavigationData } from './sidenav.data';
import { SidenavService } from './sidenav.service';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { ScrollbarModule } from 'src/@fury/shared/scrollbar/scrollbar.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [SidenavComponent, SidenavItemComponent],
  imports: [CommonModule, RouterModule, MaterialModule, ScrollbarModule, PipesModule],
  exports: [SidenavComponent, SidenavItemComponent],
  providers: [SidenavNavigationData, SidenavService]
})
export class SidenavModule {}
